<template>
    <div class="creator_container">
        <div class="creator_cont">
            <div class="header">
                <img src="@/assets/image/creator_img/creator_title.png" alt="" class="title">
                <img src="@/assets/image/creator_img/jiantou.png" class="jiantou">
            </div>
            <section class="section">
                <!-- 创作者作品展示 -->
                <div class="creator_work_cont">
                    <img src="@/assets/image/creator_img/work_titlebg.png" class="title">
                    <img src="@/assets/image/creator_img/logo.png" class="logo">
                    <div class="notice_cont">
                        <img src="@/assets/image/creator_img/notice_icon.png" class="notice_icon">
                        <swiper ref="noticeSwiper" :options="noticeswiperOption" class="swiper" v-if="noticeList.length > 0">
                            <swiper-slide v-for="(ele, i) in noticeList" :key="i">
                               <span>{{ ele.text }}</span>
                            </swiper-slide>
                        </swiper>
                    </div>
                    <div class="work_detail">
                        <swiper ref="videoSwiper" :options="swiperOption" class="swiper" v-if="workList.length">
                            <swiper-slide v-for="(item,index) in workList" :key="index">
                                <div class="work_video" >
                                    <div class="work_video_desc"  >
                                        <video ref="videoPlayer" id="video" :poster="item.workurl+'?'+'vframe/jpg/offset/'+index+7" width="236px" height="157px">
                                            <source :src="item.workurl" />
                                        </video>
                                        <div class="play_mask">
                                            <img src="@/assets/image/creator_img/play.png" class="play" @click="playWork(item.workurl)">
                                        </div>
                                    </div>
                                    <div class="creator_name">
                                        <span>创作者：{{ item.creator }}</span>
                                    </div>
                                </div>
                            </swiper-slide>
                        </swiper>
                    </div>
                    <div class="gift_explain">
                        <ul>
                            <li>即日起，<span>抖音、快手、B站、小红书</span>等平台视频博主，发布带有<br>PlayList口播、展示LOGO及网址的视频，选中即可获得三重好礼！</li>
                            <li>一重礼：PlayList会员直接<label>免费</label>赠送</li>
                            <li>二重礼：视频播放量达到规定数量，直接获赠<label>现金</label></li>
                            <li>三重礼：粉丝通过专属邀请码购买Playlist任意会员，再赠送博主<label>现金</label></li>
                        </ul>
                    </div>
                    <div class="active_mode">
                        <label>客服微信：</label>
                        <div class="erweima">
                            <img src="@/assets/image/creator_img/kefu_erweima.png" alt="">
                        </div>
                        <p class="email"><span>活动投稿邮箱：</span>{{ email }}<img src="@/assets/image/creator_img/pc_copy.png" class="copy" :data-clipboard-text="email" @click="copy"></p>
                    </div>
                </div>
                <!-- 活动奖励 -->
                <div class="active_reward">
                    <img src="@/assets/image/creator_img/reward.png" alt="">
                </div>
                <!-- 活动规则 -->
                <div class="active_rules">
                    <img src="@/assets/image/creator_img/rules_titlebg.png" class="title">
                    <img src="@/assets/image/creator_img/logo.png" class="logo">
                    <ul>
                        <li>1、本活动面向抖音、快手、B站、小红书等视频平台博主；</li>
                        <li>2、博主视频被选中，将有机会获得三重奖励：<br> 一重礼：获赠PlayList会员；<br> 二重礼：视频播放量达到规定数量，获赠现金； <br>三重礼：粉丝通过专属邀请码购买PlayList会员后获得现金回馈；</li>
                        <li>3、视频要求悬挂90天；</li>
                        <li>4、活动发送的会员及现金，与客服微信联系后完成奖励发放；</li>
                        <li>5、视频互动数据（点赞收藏转发评论）统计截止时间：视频发布之日起，一个自然月的数据累计；</li>
                        <li>6、本活动最终解释权归北京数音未来科技有限公司所有。</li>
                    </ul>
                </div>
                <div class="playlist_confirm">
                    <img src="@/assets/image/creator_img/confirm.png" alt="">
                </div>
            </section>
        </div>

         <!-- 作品详情介绍弹窗 -->
        <div class="work_dialog" v-show="dialogVisiblework">
            <div class="active_dialog_detail">
                <div class="active__dialog_video">
                    <!-- <div class="mask_cont" v-show="showMask">
                        <img src="@/assets/image/play.png" @click="playVideo">
                    </div> -->
                    <img src="@/assets/image/close_video.png" class="close_video" @click="closeVideodialog">
                    <video ref="video" :src="videoUrl" :poster="poster" id="work_video" width="720px" height="407px" controls autoplay="autoplay">
                    </video>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Clipboard from 'clipboard';
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import { noticeList, workList } from '@/config'
export default {
    data(){
        // let self = this;
        return {
            noticeList,
            workList,
            videoUrl: '',
            dialogVisiblework: false,
            poster: require('@/assets/image/poster_video.jpg'),
            email: 'huodong@playlistmusic.com.cn', // 投稿邮箱
            swiperIndex: 0,
            swiperOption: {
                loop: false,
                // simulateTouch: true, // 设置鼠标拖动无效
                autoplay: {
                    delay: 3000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false
                },
                // centeredSlides: true,
                slidesPerView: 4, // 每页展示多少条数据
                slidesPerGroup: 1, // 定义slides的数量多少为一组
                // spaceBetween: 43, // 间距
                preventClicksPropagation: false, // 拖动swiper的时候禁止点击事件
                // observer: true,       //修改swiper自己或子元素时，自动初始化swiper
                // observeParents: true, //修改swiper的父元素时，自动初始化swiper
                on: {
                    click: ()=> {
                        // console.log(event)
                        // this.realIndex 获取当前swiper的真实索引
                        // console.log(this.realIndex);
                        // self.bannerEvent(this)
                    },
                    // slideChangeTransitionEnd(){
                    //     console.log(this.realIndex);
                    //     self.bannerEvent(this.realIndex)
                    // }
                },
            },
            noticeswiperOption: {
                // 视频轮播配置项
                loop: true,
                direction: 'vertical', // 滑动方向 horizontal 水平 || vertical 垂直
                initialSlide: 0, // 默认设置从一张开始播
                autoplay: {
                    delay: 5000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false
                },
                slidesPerView: 1, // 每页展示几条数据
                slidesPerGroup: 1 // 几条数据为一组
            }
        }
    },
    components:{
        swiper,
        swiperSlide
    },
    computed:{
        videoSwiper(){
            return this.$refs.videoSwiper.swiper
        },
        noticeSwiper(){
            return this.$refs.noticeSwiper.swiper
        }
    },
    created(){
        // this.$refs.video.load()
    },
    methods:{
        /**
         * 鼠标移入轮博暂停
        */
        // on_bot_enter() {
        //     this.videoSwiper.autoplay.stop();
        // },
        /**
         * 鼠标移出轮播开始
         */
        // on_bot_leave() {
        //     this.videoSwiper.autoplay.start();
        // },
        /**
         * 打开视频弹框播放对应视频
         */
        playWork(url){
            // console.log('sssss')
            // this.videoUrl = "";
            this.dialogVisiblework = true;
            this.videoUrl = url;
            setTimeout(()=>{
                this.$refs.video.play();
            },200)
        },
        closeVideodialog(){
            this.dialogVisiblework = false;
            // this.videoUrl = "";
            this.$refs.video.pause();
        },
         /**
         * 复制邮箱到剪切板
         */
        copy(){
            var clipboard = new Clipboard('.copy')
            clipboard.on('success', () => {
                // alert("已成功复制到剪切板")
                this.$message.success('已成功复制到剪切板')
                clipboard.destroy()// 释放内存
            })
            clipboard.on('error', () => {
                this.$message.warning('不支持复制,该浏览器不支持自动复制！')
                clipboard.destroy()// 释放内存
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.creator_container{
    width: 100%;
    min-height: 5296px;
    min-width: 1440px;
    background: url('~@/assets/image/creator_img/creator_bg.png') no-repeat;
    background-size: 100% auto;
    padding-bottom: 100px;
    .creator_cont{
        width: 100%;
        .header{
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .title{
                width: 810px;
                margin: 176px auto 0px;
            }
            .jiantou{
                width: 96px;
                margin: 16px auto 0px;
            }
        }
        .section{
            width: 100%;
            margin-top: 30px;
            padding: 0px 100px;
            // 作品展示
            .creator_work_cont{
                position: relative;
                width: 100%;
                // height: 1253px;
                background: url('~@/assets/image/creator_img/kuang.png') no-repeat center;
                background-size: 100% 100%;
                padding: 90px 27px 117px 27px;
                margin-top: 140px;
                .title{
                    width: 568px;
                    position: absolute;
                    left: 50%;
                    margin-left: -284px;
                    top: -106px;
                }
                .logo{
                    width: 200px;
                    position: absolute;
                    right: 80px;
                    bottom: -20px;
                }
                .notice_cont{
                    width: 1066px;
                    height: 69px;
                    margin: 0 auto 40px;
                    background: url('~@/assets/image/creator_img/notice_bg.png') no-repeat;
                    background-size: 100% 100%;
                    display: flex;
                    align-items: center;
                    padding: 0px 26px;
                    .notice_icon{
                        width: 33px;
                    }
                    .swiper-container{
                        flex: 1;
                        height: 30px;
                        line-height: 30px;
                        span{
                            font-size: 29px;
                            font-family: MaoKenTangYuan (beta);
                            font-weight: 400;
                            color: #FEFEFE;
                            cursor: pointer;
                        }
                    }
                }
                .work_detail{
                    width: 100%;
                    height: 250px;
                    display: flex;
                    justify-content: space-between;
                    .swiper-slide{
                        // width: 257px!important;
                        margin: 0px 20px;
                    }
                    .work_video{
                        width: 257px;
                        height: 242px;
                        background: #fff;
                        border-radius: 15px;
                        box-shadow: 0px 6px 5px 2px rgba(8, 77, 170, 0.3);
                        padding: 12px 11px 15px 11px;
                        .work_video_desc{
                            width: 236px;
                            height: 157px;
                            position: relative;
                            background: url('~@/assets/image/creator_img/work_bg.png') no-repeat;
                            background-size: contain;
                            #video{
                                width: 100%;
                                object-fit: fill;
                            }
                            .play_mask{
                                z-index: 1000;
                                width: 236px;
                                height: 157px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                position: absolute;
                                left: 0px;
                                top: 0px;
                                .play{
                                    width: 52px;
                                    cursor: pointer;
                                }
                            }
                        }
                        .creator_name{
                            width: 100%;
                            height: 44px;
                            background: #FFC5BA;
                            line-height: 44px;
                            margin-top: 14px;
                            border-radius: 8px;
                            padding-left: 9px;
                            span{
                                font-size: 18px;
                                font-family: MaoKenTangYuan;
                                font-weight: 400;
                                color: #FFFFFF;
                                text-shadow: 0px 3px 7px rgba(0, 0, 0, 0.76);
                            }
                        }
                    }
                }
                .gift_explain{
                    width: 720px;
                    margin: 0 auto;
                    ul{
                        padding-top: 30px;
                        li{
                            font-size: 23px;
                            font-family: MaoKenTangYuan;
                            font-weight: 400;
                            color: #FFFFFF;
                            line-height: 50px;
                            text-shadow: 0px 3px 7px rgba(0, 0, 0, 0.76);
                            span{
                                color: #FF2727 ;
                            }
                            label{
                                font-size: 31px;
                                color: #FFFF00;
                            }
                        }
                    }
                }
                .active_mode{
                    // width: 480px;
                    margin: 0 auto;
                    text-align: center;
                    label{
                        display: block;
                        padding-top: 30px;
                        padding-right: 280px;
                        font-size: 33px;
                        font-family: shetumodengxiaofangti;
                        font-weight: 400;
                        color: #FFFFFF;
                        text-shadow: 0px 3px 7px rgba(0, 0, 0, 0.76);
                    }
                    .erweima{
                        width: 169px;
                        margin: 10px auto;
                        img{
                            width: 100%;
                        }
                    }
                    .email{
                        font-size: 22px;
                        font-family: MaoKenTangYuan (beta);
                        font-weight: 400;
                        color: #FFFFFF;
                        line-height: 23px;
                        text-shadow: 0px 3px 7px rgba(0, 0, 0, 0.76);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        span{
                             color: #FFFF00;
                        }
                        .copy{
                            width: 30px;
                            margin-left: 10px;
                            cursor: pointer;
                        }
                    }
                }
            }
            // 活动奖励
            .active_reward{
                width: 100%;
                margin-top: 80px;
                img{
                    width: 100%;
                }
            }
            .active_rules{
                width: 100%;
                height: auto;
                background: url('~@/assets/image/creator_img/kuang.png') no-repeat;
                background-size: 100% 100%;
                margin: 150px auto 0px;
                padding: 70px 199px 60px 171px;
                position: relative;
                .title{
                    width: 568px;
                    position: absolute;
                    left: 50%;
                    margin-left: -284px;
                    top: -106px;
                }
                .logo{
                    width: 200px;
                    position: absolute;
                    right: 80px;
                    bottom: -20px;
                }
                ul{
                    li{
                        font-size: 25px;
                        font-family: MaoKenTangYuan;
                        font-weight: 400;
                        color: #FFFFFF;
                        line-height: 56px;
                        text-shadow: 0px 4px 6px rgba(3, 70, 153, 0.51);
                    }
                }
            }
            .playlist_confirm{
                width: 996px;
                margin: 159px auto 0px;
                img{
                    width: 100%;
                }
            }
        }
    }

    /**
    活动介绍
    */
    .work_dialog{
        z-index: 2000;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.5);
        .active_dialog_detail{
            width: 100%;
            height: 100%;
            @include center-middle-flex;
            .active__dialog_video{
                width: 720px;
                height: 407px;
                position: relative;
                //   .mask_cont{
                //     z-index: 3000;
                //     width: 100%;
                //     height: 100%;
                //     background: rgba(0, 0, 0, 0.6);
                //     position: absolute;
                //     left: 0;
                //     top: 0;
                //     @include center-middle-flex;
                //     img{
                //       width: 80px;
                //       cursor: pointer;
                //     }
                //   }
                .close_video{
                    z-index: 3000;
                    position: absolute;
                    width: 30px;
                    position: absolute;
                    right: -40px;
                    top: 0px;
                    cursor: pointer;
                }
            }
        }
    }
}

@media screen and (min-width: 1440px) {
    .creator_container{
        .creator_work_cont{
            .work_detail{
                max-width: 1200px;
                margin: 0 auto;
                justify-content: center!important;
                .swiper-slide{
                    width: 257px!important;
                }
            }
        }
    }
}
</style>
